import { Component, Input, OnInit } from '@angular/core';

import { Closable } from '@core/interfaces/closable';
import { fadeInOut } from '@shared/utils/animations';

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.css',
  animations: [fadeInOut]
})
export class AlertComponent extends Closable implements OnInit {
  @Input() duration = 3000;
  @Input() icon: 'error' | 'info' | 'success' | 'warning' = 'success';
  @Input() message = '';
  @Input() title = 'Alert';

  styles = 'box-notification';

  ngOnInit() {
    switch (this.icon) {
      case 'error':
        this.styles += ' error-notification';
        break;
      case 'info':
        this.styles += ' information-notification';
        break;
      case 'warning':
        this.styles += ' warning-notification';
        break;
    }

    setTimeout(() => {
      this.close();
    }, this.duration);
  }
}
