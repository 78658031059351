<div class="modal-confirmation" [@fadeInOut]="state">
  <div class="container-modal-confirmation">
    <div class="modal-layer-confirmation"></div>
    <div class="modal-content-confirmation">
      <div class="modal-header-confirmation">
        <h2>{{ title }}</h2>
        <p>{{ message }}</p>
      </div>
      <div class="modal-body-confirmation">
        <form>
          <div class="row-button-confirmation">
            <button type="button" class="modal-button-cancel-confirmation" (click)="cancel()">
              {{ cancelText }}
            </button>
            <button type="button" class="modal-button-accept-confirmation" (click)="confirm()">
              {{ confirmText }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
