export const environment = {
  production: false,
  apis: {
    linkroom: 'https://admindev-api.linkroom.com',
    vip: 'https://vip-admin-api-dev.linkroom.com',
    commemorative_photo: 'https://us-central1-linkroom-v2-dev.cloudfunctions.net/api'
  },
  external: {
    admin: 'https://admin-dev2.linkroom.com',
    gallery: 'https://gallery-dev.linkroom.com',
    pixel: 'https://linkroomv2-ps.web.app/'
  },
  keys: {
    AGORA_APP_ID: '6640356cca2b4467bd60de894ce93c0e',
    INDICATIVE_API_KEY: '73e05edc-4b55-4043-905c-aff9ef6a9f50',
    PHOTON_APP_ID: '80c3db96-6a0d-4ef3-8de4-3a197adfac00',
    imagekit: {
      publicKey: 'public_HaXtq7gDdr1WTjhEhsMpoKTpp+8=',
      urlEndpoint: 'https://ik.imagekit.io/b152q4ah5/linkroom-dev'
    }
  },
  misc: {
    authDomain: 'https://linkroomv3dev.firebaseapp.com/__/auth/handler',
    enablePixelStreaming: true
  },
  firebase: {
    apiKey: 'AIzaSyAmHvWxoIoLv41qJVXeIGlo6Y-Jbn_K6DI',
    appId: '1:383066649579:web:19c4ead8fa9b62525a1fae',
    authDomain: 'linkroom-v2-dev.firebaseapp.com',
    databaseURL: 'https://linkroom-v2-dev-default-rtdb.firebaseio.com',
    measurementId: 'G-5HW1Y5YHWW',
    messagingSenderId: '383066649579',
    projectId: 'linkroom-v2-dev',
    storageBucket: 'linkroom-v2-dev.appspot.com'
  }
};
