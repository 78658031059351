import { animate, state, style, transition, trigger } from '@angular/animations';
import { KeyValue } from '@angular/common';

import { HostControl } from '@features/models/host-control.model';

import { KeyCode } from './enums';

export const APP_NAME = 'Linkroom';
export const PRESENTATION_DATA_KEY = 'linkroom';

export const PRIVACY_POLICY_URL = 'https://linkroom.com/privacy-policy';
export const TERMS_OF_USE_URL = 'https://linkroom.com/terms-of-use ';

export const DEFAULT_APPLICATION_LOGO_PATH = 'assets/images/logo.svg';
export const DEVICE_SETTINGS_URL_PATH = 'device-settings';

export const SIGN_IN_MODE = 'sign-in-mode';
export const DEFAULT_LANGUAGE = 'en';
export const CRYPTO_SEED = '3a0da83b-5d49-4224-96f9-4d9de9cc017c';

export const PROFILE_PICTURE_FORMAT = 'jpeg';

export const APP_VERSION = 3;

export const LOCAL_STORAGE_KEYS = {
  LOGOUT: 'logout',
  ATTENDEE_NAME: 'attendee-name',
  PROFILE_DATA: 'profile-data',
  ACCESS_TOKEN: 'access_token',
  PROFILE_ID: 'pid',
  USER_PREFERENCES: 'user-preferences',
  PASSWORD_CHALLENGE_COMPLETE: 'password-challenge',
  ROOM_ATTENDEE_NICKNAME: 'attendee-nickname',
  PROFILE_CURRENT_ORGANIZATION_ID: 'current-organization-id',
  PROFILE_CURRENT_ORGANIZATION_NAME: 'current-organization-name',

  SELECTED_INPUT_VIDEO_DEVICE: 'selectedInputVideoDevice',
  SELECTED_INPUT_AUDIO_DEVICE: 'selectedInputAudioDevice',
  SELECTED_OUTPUT_AUDIO_DEVICE: 'selectedOutputAudioDevice',
  JOIN_WITH_MICROPHONE: 'jowinWithMicrophone',
  JOIN_WITH_CAMERA: 'joinWithCamera',

  SELECTED_THEME: 'selected-theme',
  LIGHT_THEME_NAME: 'light-mode',
  DARK_THEME_NAME: 'dark-mode',

  GALLERY_CURRENT_VIEW: 'view',
  PERMISSIONS_LIST: 'permissions',

  NAVIGATION_TUTORIAL_REQUIRED: 'navigation-tutorial-required',
  SELECTED_LANGUAGE: 'lang',
  CLIENT_SESSION_ID: 'lsid',
  SESSION_EXPIRED: 'lsid',
  ROOM_ID_TO_REDIRECT: 'room-to-redirect',
  ORGANZATION_LOGO_URL: 'organization-logo-url'
};

export const RESELLER = {
  collection: 'resellers',
  current: 'linkroomv2-admin-dev.web.app',
  endpoint: 'resellers',
  header: 'linkroom-reseller',
  linkroom: 'ad1401fcce5df1fc12be071e9efe149505323b20e8cf9493e94150096e4549ad',
  template: 'reseller'
};

export const VIP = {
  collection: 'vips',
  endpoint: 'vips',
  template: 'activate-vip',
  vipUrl: 'vip.com'
};

export const VIP_PROFILE = {
  collection: 'vip-profiles',
  endpoint: 'vip-profiles'
};

export const EVENT = {
  collection: 'events',
  endpoint: 'events'
};

export const GUEST = {
  collection: 'guests'
};

export const ENDPOINTS = {
  accessRequests: '/access-requests',
  actions: '/actions',
  assets: '/assets',
  auth_agora: '/auth/agora',
  auth_photon: '/auth/photon',
  auth_providers: '/public/auth/providers',
  custom_worlds: '/custom-worlds',
  invites: '/invites',
  organizations: '/organizations',
  permissions: '/profiles/permissions',
  permissions_description: '/profiles/permissions-description',
  presentations: '/presentations',
  shared_presentations: '/shared',
  profiles: '/profiles',
  rooms: '/rooms',
  resellers: 'public/resellers',
  reset_password: '/auth/reset-password',
  roles: '/roles',
  scenes: '/scenes',
  supportAccess: '/support-access',
  tags: '/tags',
  verify: '/auth/verify',
  world: '/worlds',
  pixel: '/pixel-worlds',
  qr: '/qr'
};

export const HOST_CONTROLS: HostControl[] = [
  { keyText: 'mute', hotKey: 'U', keyCode: KeyCode.KEY_U },
  { id: 'moveattendees', keyText: 'moveattendees', hotKey: 'M', keyCode: KeyCode.KEY_M },
  { keyText: 'sharescreen', hotKey: 'S', keyCode: KeyCode.KEY_S },
  { keyText: 'presentation', hotKey: 'P', keyCode: KeyCode.KEY_P },
  { keyText: 'messageall', hotKey: 'G', keyCode: KeyCode.KEY_G },
  //   { keyText: 'back' },
  { keyText: 'gather', hotKey: 'G', parentId: 'moveattendees', keyCode: KeyCode.KEY_G },
  { keyText: 'look', hotKey: 'L', parentId: 'moveattendees', keyCode: KeyCode.KEY_L },
  { keyText: 'orbit', hotKey: 'O', parentId: 'moveattendees', keyCode: KeyCode.KEY_O },
  {
    keyText: 'followme',
    hotKey: 'D',
    parentId: 'moveattendees',
    keyCode: KeyCode.KEY_D,
    mirrorMe: true,
    isPermanent: true
  },
  {
    keyText: 'unfollow',
    hotKey: 'K',
    parentId: 'moveattendees',
    keyCode: KeyCode.KEY_K,
    mirrorMe: true,
    isPermanent: true
  },
  { keyText: 'freeze', hotKey: 'F', parentId: 'moveattendees', keyCode: KeyCode.KEY_F },
  { keyText: 'unfreeze', hotKey: 'U', parentId: 'moveattendees', keyCode: KeyCode.KEY_U },
  { keyText: 'pattern', hotKey: 'P', parentId: 'moveattendees', keyCode: KeyCode.KEY_P },
  { keyText: 'gotoscene', hotKey: 'S', parentId: 'moveattendees', keyCode: KeyCode.KEY_S }
];

export const APP_LANGUAGES: KeyValue<string, string>[] = [
  { key: 'en', value: 'English' },
  { key: 'es', value: 'Spanish' }
];

export const MOVEMENT_KEYS = [
  KeyCode.KEY_Q,
  KeyCode.KEY_W,
  KeyCode.KEY_E,
  KeyCode.KEY_A,
  KeyCode.KEY_S,
  KeyCode.KEY_D,
  KeyCode.ARROW_LEFT,
  KeyCode.ARROW_UP,
  KeyCode.ARROW_RIGHT,
  KeyCode.ARROW_DOWN
];
