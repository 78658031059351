import { animate, state, style, transition, trigger } from '@angular/animations';

export const FADE_DURATION = 300;

export const fadeInOut = trigger('fadeInOut', [
  state('void', style({ opacity: 0 })),
  transition(':enter', [style({ opacity: 0 }), animate('500ms ease-in', style({ opacity: 1 }))]),
  transition(':leave', [animate('500ms ease-out', style({ opacity: 0 }))])
]);

// export const fadeInOut2 = trigger('fadeInOut', [
//   state('void', style({ opacity: 0 })),
//   transition(':enter', [animate(`${FADE_DURATION}ms ease-in`, style({ opacity: 1 }))]),
//   transition(':leave', [animate(`${FADE_DURATION}ms ease-out`, style({ opacity: 0 }))])
// ]);

export const slideRightToLeft = trigger('slideRightToLeft', [
  state(
    'in',
    style({
      right: '0'
    })
  ),
  state(
    'out',
    style({
      right: '-100%' // adjust this value to be offscreen or hidden as per your layout
    })
  ),
  transition('out => in', [animate('300ms ease-in-out')]),
  transition('in => out', [animate('300ms ease-in-out')])
]);

export const dropdownAnimation = trigger('dropdownAnimation', [
  state('void', style({ opacity: 0, transform: 'translateY(-10px)' })),
  transition('void => *', [
    animate('200ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
  ]),
  transition('* => void', [
    animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(-10px)' }))
  ])
]);
