export enum FirebaseAuthMethod {
  EMAIL = 'email',
  GOOGLE = 'google',
  FACEBOOK = 'facebook'
}

export enum RoomFormAction {
  CREATE = 'create',
  EDIT = 'edit',
  COPY = 'copy',
  REMOVE = 'remove'
}

export enum RoomSidebarOptions {
  ATTENDEES = 'attendees',
  CHAT = 'chat',
  SCENE_OBJECTS = 'scene-objects'
}

export enum PendingAttendeeStatus {
  WAITING = 'waiting',
  ALLOWED = 'allowed',
  DENIED = 'denied'
}

export enum RoomCameraContainer {
  ROOM = 'user-mirror-video',
  USER_SETTINGS = 'user-settings',
  SELFIE = 'user-selfie-video'
}

export enum DatabaseChildStatus {
  ADDED = 'added',
  CHANGED = 'changed',
  REMOVED = 'removed'
}

export enum EventUserStatus {
  PENDING = 0,
  ONGOING = 1,
  DONE = 2,
  REMOVED = 3
}

export enum RoomTopBarType {
  REMAINING_TIME = 'remainingTime',
  QUEUE_STATUS = 'queueStatus',
  HIDDEN = 'hidden'
}

export enum FanPopupType {
  WELCOME = 'welcome',
  FAREWELL = 'farewell'
}

export enum SoundPath {
  DING = '/assets/audio/ding.wav',
  COUNT_DOWN = '/assets/audio/countdown.wav',
  MESSAGE_NOTIFICATION = '/assets/audio/message-notification.wav',
  ALERT_NOTIFICATION = '/assets/audio/alert-notification.wav'
}

export enum DefaultImage {
  BLANK = '/assets/images/blank.png',
  BLANK_BORDER = '/assets/images/blank-border.png',
  RED_BORDER = '/assets/images/red-border.png',
  LINKROOM = '/assets/images/logo.svg',
  VIDEO = '/assets/images/noVideoLogo.png'
}

export enum SceneObjectType {
  COLLIDER = 'collider',
  MODEL = 'model'
}

export enum RoomUrlType {
  ROOM = 'room',
  PRESENTATION = 'presentation',
  SINGLE_LOCAL = 'single-local'
}

export enum SelfieCameraStatus {
  TAKE_AGAIN = 'take-again',
  SNAP = 'snap',
  TAKE_PHOTO = 'take-photo'
}

export enum RoomType {
  LINKROOM = 'Linkroom',
  PIXEL_STREAMING = 'Pixel Streaming',
  CUSTOM_WORLDS = 'Custom Worlds'
}

export enum NetworkMessageCode {
  SEND_MESSAGE_TO_PLAYER = 81
}

export enum GalleryFieldType {
  AR_MODEL = 'AR Model',
  AR_PREVIEW = 'AR Preview',
  IMAGE = 'Image',
  IMAGE_GALLERY = 'Image Gallery',
  MULTILINE_TEXT = 'Multiline Text',
  QR_CODE = 'QR Code',
  SCREEN = 'Screen',
  TEXT = 'Text',
  VIDEO = 'Video'
}

export enum SceneObjectCarouselType {
  IMAGE = 'Image',
  QR_CODE = 'QR Code',
  VIDEO = 'Video'
}

export enum SelectedObjectStatus {
  STAND_BY = 'stand by',
  ORBITING = 'orbiting'
}

export enum KeyCode {
  // Function Keys
  F1 = 'F1',
  F2 = 'F2',
  F3 = 'F3',
  F4 = 'F4',
  F5 = 'F5',
  F6 = 'F6',
  F7 = 'F7',
  F8 = 'F8',
  F9 = 'F9',
  F10 = 'F10',
  F11 = 'F11',
  F12 = 'F12',

  // Number Row
  DIGIT_0 = 'Digit0',
  DIGIT_1 = 'Digit1',
  DIGIT_2 = 'Digit2',
  DIGIT_3 = 'Digit3',
  DIGIT_4 = 'Digit4',
  DIGIT_5 = 'Digit5',
  DIGIT_6 = 'Digit6',
  DIGIT_7 = 'Digit7',
  DIGIT_8 = 'Digit8',
  DIGIT_9 = 'Digit9',

  // Alphabet Keys
  KEY_A = 'KeyA',
  KEY_B = 'KeyB',
  KEY_C = 'KeyC',
  KEY_D = 'KeyD',
  KEY_E = 'KeyE',
  KEY_F = 'KeyF',
  KEY_G = 'KeyG',
  KEY_H = 'KeyH',
  KEY_I = 'KeyI',
  KEY_J = 'KeyJ',
  KEY_K = 'KeyK',
  KEY_L = 'KeyL',
  KEY_M = 'KeyM',
  KEY_N = 'KeyN',
  KEY_O = 'KeyO',
  KEY_P = 'KeyP',
  KEY_Q = 'KeyQ',
  KEY_R = 'KeyR',
  KEY_S = 'KeyS',
  KEY_T = 'KeyT',
  KEY_U = 'KeyU',
  KEY_V = 'KeyV',
  KEY_W = 'KeyW',
  KEY_X = 'KeyX',
  KEY_Y = 'KeyY',
  KEY_Z = 'KeyZ',

  // Numpad Keys
  NUMPAD_0 = 'Numpad0',
  NUMPAD_1 = 'Numpad1',
  NUMPAD_2 = 'Numpad2',
  NUMPAD_3 = 'Numpad3',
  NUMPAD_4 = 'Numpad4',
  NUMPAD_5 = 'Numpad5',
  NUMPAD_6 = 'Numpad6',
  NUMPAD_7 = 'Numpad7',
  NUMPAD_8 = 'Numpad8',
  NUMPAD_9 = 'Numpad9',
  NUMPAD_ADD = 'NumpadAdd',
  NUMPAD_SUBTRACT = 'NumpadSubtract',
  NUMPAD_MULTIPLY = 'NumpadMultiply',
  NUMPAD_DIVIDE = 'NumpadDivide',
  NUMPAD_DECIMAL = 'NumpadDecimal',
  NUMPAD_ENTER = 'NumpadEnter',

  // Arrow Keys
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',

  // Modifier Keys
  SHIFT_LEFT = 'ShiftLeft',
  SHIFT_RIGHT = 'ShiftRight',
  CONTROL_LEFT = 'ControlLeft',
  CONTROL_RIGHT = 'ControlRight',
  ALT_LEFT = 'AltLeft',
  ALT_RIGHT = 'AltRight',
  META_LEFT = 'MetaLeft', // Windows/Command key on the left
  META_RIGHT = 'MetaRight', // Windows/Command key on the right

  // Special Keys
  ESCAPE = 'Escape',
  TAB = 'Tab',
  CAPS_LOCK = 'CapsLock',
  BACKSPACE = 'Backspace',
  ENTER = 'Enter',
  SPACE = 'Space',
  DELETE = 'Delete',
  INSERT = 'Insert',
  HOME = 'Home',
  END = 'End',
  PAGE_UP = 'PageUp',
  PAGE_DOWN = 'PageDown',

  // Brackets and Symbols
  BACKQUOTE = 'Backquote', // `
  MINUS = 'Minus', // -
  EQUAL = 'Equal', // =
  BRACKET_LEFT = 'BracketLeft', // [
  BRACKET_RIGHT = 'BracketRight', // ]
  BACKSLASH = 'Backslash', // \
  SEMICOLON = 'Semicolon', // ;
  QUOTE = 'Quote', // '
  COMMA = 'Comma', // ,
  PERIOD = 'Period', // .
  SLASH = 'Slash' // /
}
