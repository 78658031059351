import { Component, Input } from '@angular/core';

import { Closable } from '@core/interfaces/closable';
import { fadeInOut } from '@shared/utils/animations';

@Component({
  selector: 'app-confirm',
  standalone: true,
  imports: [],
  templateUrl: './confirm.component.html',
  styleUrl: './confirm.component.css',
  animations: [fadeInOut]
})
export class ConfirmComponent extends Closable {
  @Input() cancelText = 'No';
  @Input() confirmText = 'Yes';
  @Input() message = 'Are you sure?';
  @Input() title = 'Confirm';

  cancel() {
    this.result.next(false);
    this.close();
  }

  confirm() {
    this.result.next(true);
    this.close();
  }
}
